import '@emotion/react';
import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    common: {
      flexCenter: string;
      flexCenterColumn: string;
    };
    colors: {
      black: string;
      grey: string;
      green: string;
      blue: string;
      white: string;
    };
  }
}

// pixel > rem
// export const pxToRem = (size: number) => `${size / 16}rem`;
const colors = {
  black: '#000000',
  grey: '#999999',
  green: '#3cb46e',
  blue: '#000080',
  white: '#fff',
};

// common property
const common = {
  flexCenter: `
    display: flex;
    justify-contents: center;
    align-items: center;
  `,
  flexCenterColumn: `
    display: flex;
    flex-direction: column;
    justify-contents: center;
    align-items: center;
  `,
};

const theme: Theme = {
  colors,
  common,
};

export { theme };
