import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@emotion/react';
import { theme } from '@/constants/theme';
import Home from '@/pages/Home';
import smoothscroll from 'smoothscroll-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './utils/i18n';

smoothscroll.polyfill();
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
