import styled from '@emotion/styled/macro';
import { HTMLAttributes } from 'react';

const Icon = styled.button`
  display: flex;
  flex-direction: column;
  gap: 3px;

  div {
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: #fff;
  }
`;

interface MenuIconProps extends HTMLAttributes<HTMLButtonElement> {}

function MenuIcon({ ...rest }: MenuIconProps) {
  return (
    <Icon {...rest}>
      <div></div>
      <div></div>
      <div></div>
    </Icon>
  );
}

export default MenuIcon;
