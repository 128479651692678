import styled from '@emotion/styled/macro';

export const StyledSlantBottomWrapper = styled.div`
  position: relative;
  top: auto;
  right: 0px;
  bottom: auto;
  overflow: hidden;
  width: 100%;
  height: 14vh;
  max-height: 180px;
  background-color: transparent;
  transform: skew(0deg, 0deg) translate3d(0px, 0px, 24px);
  color: transparent;
  transform-style: preserve-3d;
`;

const StyledSlantTop = styled.div`
  position: absolute;
  inset: auto auto -5px 0;
  z-index: -1;
  display: flex;
  overflow: visible;
  width: 54%;
  height: 15vh;
  max-height: 180px;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: rgb(38, 38, 39);
  transform: skew(41deg, 0deg);
`;

const StyledSlantTopInSpace = styled.div`
  position: absolute;
  inset: auto auto 0% 0%;
  overflow: hidden;
  width: 45%;
  height: 14vh;
  max-height: 90px;
  min-height: auto;
  min-width: auto;
  margin-left: -40%;
  background-color: rgb(38, 38, 39);
`;

interface SlantTopProps {
  className?: string;
}
function SlantTop({ className }: SlantTopProps) {
  return (
    <StyledSlantBottomWrapper className={className}>
      <StyledSlantTop>
        <StyledSlantTopInSpace />
      </StyledSlantTop>
    </StyledSlantBottomWrapper>
  );
}

export default SlantTop;
