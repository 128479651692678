import styled from "@emotion/styled/macro";
import Marquee from "react-fast-marquee";
import Pic2 from "@/assets/images/team/color-1.jpg";
import Pic3 from "@/assets/images/team/color-2.jpg";
import Pic9 from "@/assets/images/team/color-3.jpg";
import Pic4 from "@/assets/images/team/color-4.jpg";
import Pic6 from "@/assets/images/team/color-5.jpg";
import Pic8 from "@/assets/images/team/color-6.jpg";
import Pic11 from "@/assets/images/team/color-7.jpg";
import Pic10 from "@/assets/images/team/color-8.jpg";
import Pic1 from "@/assets/images/team/color-9.jpg";
import Pic7 from "@/assets/images/team/color-10.jpg";
import Pic12 from "@/assets/images/team/color-11.jpg";
import Pic5 from "@/assets/images/team/color-12.jpg";
import { MOBILE_SIZE } from "@/constants/constant";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  img {
    height: 474px;
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(0);
    }
  }

  .marquee + .marquee {
    margin-left: 0;
  }

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    img {
      height: 240px;
    }
  }
`;

function Teams() {
  return (
    <Wrapper>
      <Marquee pauseOnHover={true} speed={100}>
        <img alt="" src={Pic1} />
        <img alt="" src={Pic2} />
        <img alt="" src={Pic3} />
        <img alt="" src={Pic4} />
        <img alt="" src={Pic5} />
        <img alt="" src={Pic6} />
      </Marquee>
      <Marquee direction="right" pauseOnHover={true} speed={100}>
        <img alt="" src={Pic7} />
        <img alt="" src={Pic8} />
        <img alt="" src={Pic9} />
        <img alt="" src={Pic10} />
        <img alt="" src={Pic11} />
        <img alt="" src={Pic12} />
      </Marquee>
    </Wrapper>
  );
}

export default Teams;
