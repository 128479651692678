import Logo from '@/assets/images/childly_logo_white.png'
import styled from '@emotion/styled/macro';
import { Link } from 'react-scroll';
import i18n from '@/utils/i18n';

const WaterMark = styled.img`
  display: inline-block;
`;

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  align-items: end;
  padding: 70px 40px 0 40px;
  background: #2d2d2f;
  overflow: auto;
  z-index: 999;

  nav {
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  ul {
    overflow: auto;
  }

  li {
    float: left;
    font-size: 16px;
    font-weight: 400;
  }

  li + li {
    margin-left: 20px;
  }

  a {
    display: inline-block;
  }

  aside {
    margin-left: auto;
    padding-bottom: 33px;

    a {
      padding-bottom: 0;
      border-width: 1px;
    }
  }

  a {
    opacity: 0.2;
    padding-bottom: 33px;
    border-bottom: 2px solid transparent;

    &.active,
    &:hover {
      opacity: 1;
      border-color: #fff;
    }
  }

  ${WaterMark} {
    transform: translateY(-30px);
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  ${WaterMark} + ul {
    margin-left: 40px;
  }
`;

function Header() {
  const changeLanguage = (lng: 'ko' | 'en') => {
    i18n.changeLanguage(lng);
  };

  return (
    <StyledHeader>
      <nav className="max-width">
        <WaterMark src={Logo} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
        <ul>
          <li>
            <Link to="products" offset={-110} smooth={true} spy={true}>
              PRODUCTS
            </Link>
          </li>
          <li>
            <Link to="team" offset={-110} smooth={true} spy={true}>
              TEAM
            </Link>
          </li>
          <li>
            <Link to="careers" offset={-115} smooth={true} spy={true}>
              CAREERS
            </Link>
          </li>
          <li>
            <Link to="press" offset={-110} smooth={true} spy={true}>
              PRESS
            </Link>
          </li>
          <li>
            <a href="mailto:cs@childly.com">CONTACT</a>
          </li>
        </ul>
        <aside>
          <dl>
            <li>
              <a onClick={() => changeLanguage('ko')} className={i18n.language.includes('ko') ? 'active' : ''}>
                KR
              </a>
            </li>
            <li>
              <a onClick={() => changeLanguage('en')} className={i18n.language.includes('en') ? 'active' : ''}>
                EN
              </a>
            </li>
          </dl>
        </aside>
      </nav>
    </StyledHeader>
  );
}

export default Header;
