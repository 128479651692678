import { useState } from "react";
import styled from "@emotion/styled/macro";
import { HTMLAttributes } from "react";
import { ReactComponent as Arrow1 } from "@/assets/images/arrow1.svg";
import { Article } from "../../types";
import i18n from "@/utils/i18n";

const StyledArrow1 = styled(Arrow1)`
  margin: 30px 0 30px 0;
  width: 50px;
  height: 50px;
  align-self: center;
  fill: #adadad;
`;

const StyledContainer = styled.a<{ active?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #adadad;
  margin-bottom: 10px;

  h3,
  p {
    z-index: 1;
    font-family: MontserratRegular;
    font-size: 16px;
    line-height: 32px;
  }

  h3 {
    color: #adadad;
  }

  p {
    width: 100%;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    svg {
      fill: #fff;
    }

    p.title {
      font-family: MontserratBold;
    }
  }

  .clicked {
    font-family: MontserratBold;
  }
`;

const StyledPressItem = styled.div<{ active?: boolean }>`
  width: 85%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

const StyledArticleBody = styled.div<{ active?: boolean }>`
  width: 100%;

  p {
    width: 100%;
    color: #ffffff;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }
`;

export interface PressItemProps extends HTMLAttributes<HTMLDivElement> {
  article: Article;
}

function getDateTimeWithSeconds(seconds: number, nanoseconds?: number) {
  const millisecond = nanoseconds
    ? seconds * 1000 + Math.floor(nanoseconds / 1000000)
    : seconds * 1000;

  return new Date(millisecond);
}

function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dateOfMonth = date.getDate();

  return month < 10
    ? `${year}.0${month}.${dateOfMonth}`
    : `${year}.${month}.${dateOfMonth}`;
}

function formatDateTime(dateTime: Date) {
  const date = formatDate(dateTime);
  const hour = dateTime.getHours();
  const minute = dateTime.getMinutes();

  return `${date} ${hour < 10 ? `0${hour}` : hour}:${
    minute < 10 ? `0${minute}` : minute
  }`;
}

function PressItem({ article }: PressItemProps) {
  const dateTime = getDateTimeWithSeconds(
    article.date.seconds,
    article.date.nanoseconds
  );

  const [showBody, setShowBody] = useState(false);
  const [hasBody] = useState(!!article.body);

  return (
    <StyledContainer
      href={!article.body ? article.link : ''}
      target={"_blank"}
      onClick={() => {
        if (hasBody) setShowBody(!showBody);
      }}
    >
      <StyledPressItem>
        <h3>{formatDate(dateTime)}</h3>
        <p className={`title ${showBody ? "clicked" : ""}`}>
          {i18n.language.includes("ko") ? article.title.ko : article.title.en}
        </p>
      </StyledPressItem>
      <StyledArrow1/>
      {article.body && showBody && (
        <StyledArticleBody data-aos="fade-right" data-aos-anchor-placement="center-bottom">
          <p>{formatDateTime(dateTime)}</p>
          <br></br>
          {i18n.language.includes("ko")
            ? article.body.ko.split("\\n\\n").map((text) => (
                <div key={text}>
                  <p>{text}</p>
                  <br></br>
                </div>
              ))
            : article.body.en.split("\\n\\n").map((text) => (
                <div key={text}>
                  <p>{text}</p>
                  <br></br>
                </div>
              ))}
        </StyledArticleBody>
      )}
    </StyledContainer>
  );
}

export default PressItem;
