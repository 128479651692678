import { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import Page from "@/components/templates/Page";
import Marquee from "react-fast-marquee";
import SubtractImg from "@/assets/images/more.svg";
import Dominance01Img from "@/assets/images/bitcoin-dominance01.svg";
import Dominance02Img from "@/assets/images/bitcoin-dominance02.svg";
import MainBgImg from "@/assets/images/main_bg.svg";
import HistoryItem from "@/components/modules/HistoryItem";
import PressItem from "@/components/modules/PressItem";
import Logo from "@/assets/images/childly_logo_white.png";
import Teams from "@/components/modules/Teams";
import SlantBottom from "@/components/atoms/SlantBottom";
import SlantTop from "@/components/atoms/SlantTop";
import Header from "@/components/modules/Header";
import { Link } from "react-scroll";
import { MOBILE_SIZE } from "@/constants/constant";
import MobileHeader from "@/components/modules/MobileHeader";
import SideBar from "@/components/modules/SideBar";
import { ReactComponent as Triangle1 } from "@/assets/images/triangle1.svg";
import { ReactComponent as Triangle2 } from "@/assets/images/triangle2.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import Bubbles from "@/components/atoms/Bubbles";
import { useTranslation } from "react-i18next";
import i18n from "@/utils/i18n";
import getFirestore from "@/utils/firestore";
import { DocumentData } from "firebase/firestore/lite";
import { Article } from "@/types";

const numberOfArticlesToShow = 4;

const WaterMark = styled.img`
  display: inline-block;
  height: 40px;
`;

const StyledSlantBottom = styled.div`
  position: absolute;
  left: -30px;
  bottom: -74px;
  width: auto;
  height: auto;
  border-style: solid;
  border-width: 160px 0px 80px 120px;
  border-color: black;
  background-color: rgb(38, 38, 39);
  transform: rotate(140deg);
`;

const MainBg = styled.img`
  position: absolute;
  inset: 0 0 auto auto;
  width: -webkit-fill-available;
  width: 500px;
  aspect-ratio: auto 650 / 650;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    display: none;
  }
`;

const Bg1 = styled.div`
  position: absolute;
  inset: 0% 0% 0% auto;
  overflow: hidden;
  width: 52%;
  height: 86vh;
  max-height: 968px;
  background-color: rgb(38, 38, 39);
  z-index: -1;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    display: none;
  }
`;

const Dominance01 = styled.img`
  position: absolute;
  top: 350px;
  right: 0;
  z-index: -1;
`;

const Dominance02 = styled.img`
  position: absolute;
  bottom: 100px;
  left: 0;
  z-index: -1;
`;

const StyledTriangle1 = styled(Triangle1)`
  position: absolute;
  top: -160px;
  left: -114px;
`;

const StyledTriangle2 = styled(Triangle2)`
  position: absolute;
  top: 3px;
  left: 400px;
`;

const StyledTriangle3 = styled(Triangle1)`
  position: absolute;
  top: -230px;
  left: calc(50% - 138px);
`;

const StyledTriangle4 = styled(Triangle2)`
  position: absolute;
  top: 10px;
  left: calc(50% + 200px);
`;

const TeamPictures = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 284px;
  background-color: rgb(38, 38, 39);
  transform: translateX(10px);
  color: black;
  font-size: 40px;
`;

const StyledPage = styled(Page)`
  .history-wrapper {
    margin-top: 120px;
  }

  .press-wrapper {
    margin-top: 30px;
    align-items: center;
  }

  margin-bottom: 180px;
  background: url(Bg1) 0 0 no-repeat;
  overflow: hidden;
`;

const Section = styled.section<{ padding?: string, margin?: string }>`
  position: relative;
  padding: 0 40px;

  img {
    max-width: 450px;
    width: 100%;
  }

  &.hero {
    height: 94vh;
    max-height: 858px;
    padding-top: 14vh;
    padding-bottom: 14vh;
    justify-content: flex-start;
  }

  &.bg-grey {
    background-color: rgb(38, 38, 39);
  }

  .more-btn,
  .close-btn,
  .more-article-btn,
  .close-article-btn {
    font-family: Montserrat;
    font-size: 14px;
    text-decoration: underline;
  }

  .more-btn {
    transform: translateY(-14px);
  }

  .close-btn {
    transform: translate(5px, 20px);
    color: #fff;
  }

  .more-article-btn,
  .close-article-btn {
    margin-top: 11px;
    width: 100%;
    line-height: 17px;
  }

  ${(p) =>
    p.padding &&
    `
    padding: ${p.padding};
  `}

  ${(p) =>
    p.margin && `margin: ${p.margin};
  `}
  @media screen and (max-width: ${MOBILE_SIZE}px) {
    &#products {
      img {
        position: relative;
        inset: auto;
        margin-top: 40px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    &.hero {
      height: 924px;
      max-height: 924px;
    }
  }
`;

const Footer = styled.footer<{ lang?: string }>`
  padding: 70px 40px 90px 40px;
  background-color: #2d2d2f;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul {
    overflow: hidden;
  }

  li {
    position: relative;
    float: left;
    a {
      font-size: 14px;
      font-weight: 400;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }

    a.privacy {
      font-size: ${p => p.lang === 'ko' ? '16px' : '14px'};
      font-weight: 900;
      font-family: GoodTimesHeavy;
      opacity: 0.8;
    }
  }

  li + li {
    &:before {
      content: "";
      display: inline-block;
      margin: 0 13px;
      width: 1px;
      height: 14px;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  aside {
    display: flex;

    a {
      display: inline-block;
      background: white;
      width: 24px;
      height: 24px;
      border-radius: 100%;
    }

    a + a {
      margin-left: 16px;
    }
  }

  address {
    position: relative;
    margin-top: 52px;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    line-height: 2.15;
    opacity: 0.7;
    font-family: Montserrat;

    small {
      display: block;
      margin-top: 52px;
      font-family: Montserrat;
    }

    ${WaterMark} {
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0.2;
    }
  }

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    address ${WaterMark} {
      position: relative;
      inset: auto;
      display: block;
      margin: 0 auto;
      margin-top: 24px;
    }

    .wrapper {
      align-items: flex-start;
      flex-direction: column;
      gap: 40px;

      align-items: center;
    }

    aside a {
      width: 32px;
      height: 32px;
    }

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      li {
        text-align: center;
        a {
          font-size: 20px;
        }
      }

      li + li {
        margin-top: 16px;

        &:before {
          display: none;
        }
      }
    }
  }
`;

const Title = styled.h1`
  max-width: 455px;
  font-family: GoodTimesHeavy;
  font-size: 55px;
  line-height: 1.45;
`;

const SubTitle = styled.h2<{ paddingTop?: number }>`
  position: relative;
  max-width: 1024px;
  font-family: GoodTimesHeavy;
  font-size: 40px;
  line-height: 2;

  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : 0)}px;

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    font-size: 32px;
  }
`;

const Description = styled.p<{
  maxWidth?: number;
  opacity?: number;
  marginTop?: number;
}>`
  opacity: ${(p) => (p.opacity ? p.opacity : 1)};
  margin-top: 40px;
  white-space: pre-wrap;
  line-height: 2;
  font-family: Montserrat;
  color: #d9d9d9;

  ${(p) =>
    p.maxWidth &&
    `
    max-width: ${p.maxWidth}px;
  `}

  ${(p) =>
    p.marginTop &&
    `
    margin-top: ${p.marginTop}px;
  `}
`;

const Layout = styled.div`
  .max-width {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
  }
`;

const Cv = styled.a<{ marginTop?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 367px;
  width: 100%;
  height: 59px;
  margin-bottom: 130px;
  font-family: GoodTimesHeavy;
  font-size: 17px;
  text-indent: 60px;
  background: url(${SubtractImg}) -10px 0 no-repeat;
  margin-top: ${(p) => (p.marginTop ? p.marginTop : 0)}px;
`;

const History = styled.div`
  h3,
  p {
    font-family: GoodTimesHeavy;
    color: rgba(255, 255, 255, 0.2);
  }

  h3 {
    font-size: 25px;
    line-height: 1.6;
  }

  p {
    font-size: 35px;
    line-height: 1;
    word-break: break-word;
    margin-bottom: ${i18n.language.includes("ko") ? '12px' : '18px'}; 
  }

  &:hover {
    h3,
    p {
      color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
    }
  }
`;

const HistoryCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
`;

const Products = styled.div<{
  active?: boolean;
  marginTop?: number;
  alignRight?: boolean;
}>`
  margin-top: ${(p) => (p.marginTop ? p.marginTop : 80)}px;

  ${(p) =>
    p.alignRight &&
    `
    text-align: right;
  `};

  h3,
  p {
    font-family: GoodTimesHeavy;
    color: rgba(255, 255, 255, ${(p) => (p.active ? 0.7 : 0.3)});
  }
  h3 {
    font-size: 14px;
    line-height: 3;
  }
  p {
    font-size: 50px;
    line-height: 1.2;
  }

  @media screen and (max-width: ${MOBILE_SIZE}px) {
    h3 {
      font-size: 12px;
    }

    p {
      font-size: 32px;
    }
  }
`;

const TeamBtx = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 0 20px 0;
  font-family: Montserrat;
  line-height: 1.2;
  color: #d9d9d9;
`;

const PressList = styled.div`
  margin-bottom: 50px;
`;

const StyledMarquee = styled(Marquee)`
  position: absolute !important;
  bottom: -96px;
  left: 0;
  right: 0;
`;

const StyledSlantTop = styled(SlantTop)`
  position: absolute;
  left: -122px;
  bottom: -220px;
`;

function Home() {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_SIZE);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [more, setMore] = useState(false);
  const [moreArticle, setMoreArticle] = useState(false);

  const histories = [
    {
      title: "2022.05",
      content: t("Dove Wallet Rebranded to BTX"),
    },
    {
      title: "2021.12",
      content: t("Virtual Asset Service Provider (VASP) License Acquired"),
    },
    {
      title: "2021.12",
      content: t("Deposited crypto assets reach over $31 million USD"),
    },
    {
      title: "2021.01",
      content: t("Crypto Savings Products offered"),
    },
    {
      title: "2020.11",
      content: t("Dove Wallet Exceeds 370,000 Users"),
    },
    {
      title: "2019.11",
      content: t("Dove Wallet Exceeds 70,000 Users"),
    },
    {
      title: "2019.04",
      content: t("Official Launch of Dove Wallet"),
    },
    {
      title: "2014.01",
      content: t("Samsung Global Smart App Challenge 2013 Award"),
    },
    {
      title: "2013.05",
      content: t(
        "Selected as the ‘self-employed business creative enterprises growth & technological development project’ by Korea TIPA"
      ),
    },
    {
      title: "2013.02",
      content: t("Childly Founded"),
    },
    {
      title: "2012.12",
      content: t("Samsung Global Smart App Challenge 2012 Award"),
    },
  ];

  const [articles, setArticles] = useState<Array<DocumentData>>([]);

  useEffect(() => {
    const findArticles = async () => {
      const data = await getFirestore();
      setArticles(data);
    };
    findArticles();
  }, []);

  const handleSideBarToggle = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };

  useEffect(() => {
    AOS.init({ offset: 30 });

    function mobileCheck() {
      setIsMobile(window.innerWidth <= MOBILE_SIZE);
    }

    window.addEventListener("resize", mobileCheck);
    // window.removeEventListener('resize', mobileCheck);
    return () => {
      window.removeEventListener("resize", mobileCheck);
    };
  }, []);

  return (
    <Layout>
      {!isMobile ? <Header /> : <MobileHeader onToggle={handleSideBarToggle} />}
      {isMobile && (
        <SideBar isShow={isSideBarOpen} onClose={handleSideBarToggle} />
      )}
      <StyledPage>
        <Section id="overview" className="hero">
          <div className="max-width">
            <Bubbles />
            <MainBg
              src={MainBgImg}
              className="animate__animated animate__shakeY animate__infinite animate__slowestt"
            />
            <Title
              data-aos="fade-down"
              data-aos-anchor-placement="center-bottom"
            >
              {t("Crypto Made Easy")}
            </Title>
            <Description opacity={0.6} maxWidth={565} marginTop={80}>
              {t(
                "Childly operates BTX, a digital asset exchange. Our priority is the safety of your assets, and we are committed to providing easy-to-use, secure services that provide our users with the most number of options in utilizing their assets."
              )}
            </Description>
            <Cv
              data-aos="flip-down"
              href="https://btxpro.co.kr"
              target="_blank"
              marginTop={110}
            >
              {t("Trade now")}
            </Cv>
          </div>
          <Bg1>
            <StyledSlantBottom />
          </Bg1>
          <StyledSlantTop />
          {/* <BgTail /> */}
        </Section>
        <Section className="bg-grey" padding="100px 40px 360px 40px" margin="220px 0 0 0">
          <div className="max-width">
            <StyledTriangle1 className="animate__animated animate__shakeY animate__infinite animate__slower" />
            <StyledTriangle2 className="animate__animated animate__shakeY animate__infinite animate__slowest" />
            <SubTitle paddingTop={83} data-aos="fade-down">
              Our Vision
            </SubTitle>
            <Description data-aos="fade-left">
              {t(
                "BTX continues to innovate and to improve upon its services.  Web3 is a continually evolving space, and BTX is committed to bringing the latest in the blockchain technologies to its users, so they may reap the fruits of financial innovations that decentralized world has to offer, while enjoying the security that was previously only existent in traditional financial institutions."
              )}
            </Description>
            <div className="history-wrapper">
              <History data-aos="flip-left">
                <h3>2022.05</h3>
                <p>{t("Dove Wallet Rebranded to BTX")}</p>
              </History>
              <History data-aos="flip-right">
                <h3>2021.12</h3>
                <p>
                  {t("Virtual Asset Service Provider (VASP) License Acquired")}
                </p>
              </History>
              <History data-aos="flip-left">
                <h3>2021.12</h3>
                <p>{t("Deposited crypto assets reach over $31 million USD")}</p>
              </History>
              <History data-aos="flip-right">
                <h3>2021.01</h3>
                <p>{t("Crypto Savings Products offered")}</p>
              </History>
              <History data-aos="flip-right">
                <h3>2020.11</h3>
                <p>
                  {t("Dove Wallet Exceeds 370,000 Users")}{" "}
                  {!more && (
                    <button
                      className="more-btn"
                      type="button"
                      onClick={() => setMore(true)}
                    >
                      {t("more")}
                    </button>
                  )}
                </p>
              </History>
              {more && (
                <>
                  <History data-aos="flip-left">
                    <h3>2019.11</h3>
                    <p>{t("Dove Wallet Exceeds 70,000 Users")}</p>
                  </History>
                  <History data-aos="flip-right">
                    <h3>2019.04</h3>
                    <p>{t("Official Launch of Dove Wallet")}</p>
                  </History>
                  <History data-aos="flip-left">
                    <h3>2014.01</h3>
                    <p>{t("Samsung Global Smart App Challenge 2013 Award")}</p>
                  </History>
                  <History data-aos="flip-right">
                    <h3>2013.05</h3>
                    <p>
                      {t(
                        "Selected as the ‘self-employed business creative enterprises growth & technological development project’ by Korea TIPA"
                      )}
                    </p>
                  </History>
                  <History data-aos="flip-left">
                    <h3>2013.02</h3>
                    <p>{t("Childly Founded")}</p>
                  </History>
                  <History data-aos="flip-right">
                    <h3>2012.12</h3>
                    <p>{t("Samsung Global Smart App Challenge 2012 Award")}</p>
                  </History>
                  {more && (
                    <button
                      className="close-btn"
                      type="button"
                      onClick={() => setMore(false)}
                    >
                      {t("close")}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <StyledMarquee speed={100} pauseOnHover={true}>
            <HistoryCards>
              {histories.map((history) => (
                <HistoryItem key={history.content} title={history.title}>
                  {history.content}
                </HistoryItem>
              ))}
            </HistoryCards>
          </StyledMarquee>
        </Section>
        <SlantBottom />
        <Section id="products">
          <div className="max-width">
            <SubTitle paddingTop={120} data-aos="fade-right">
              Products
            </SubTitle>
            <Description data-aos="fade-right">
              {t("BTX offers different services to meet the")}
              <br />
              {t("many needs of our investors.")}
            </Description>
            <Products active marginTop={108} data-aos="fade-right">
              <h3>BTXPRO.CO.KR</h3>
              <p>
                Crypto
                <br />
                Exchange
              </p>
            </Products>
            <Dominance01 src={Dominance01Img} />
            <Products active alignRight marginTop={260} data-aos="fade-left">
              <h3>Coming soon</h3>
              <p>Digital Wallet</p>
            </Products>
            {/* <Products active alignRight data-aos="fade-left">
              <h3>Coming soon</h3>
              <p>
                Nft Market
                <br />
                Place
              </p>
            </Products> */}
            <Products active alignRight data-aos="fade-left">
              <h3>Coming soon</h3>
              <p>
                Investment
                <br />
                Products
              </p>
            </Products>
            <Products active alignRight data-aos="fade-left">
              <h3>Coming soon</h3>
              <p>
                Crypto
                <br />
                Payments
              </p>
            </Products>
            <Dominance02 src={Dominance02Img} />
          </div>
        </Section>
        <SlantTop />
        <Section id="team" className="bg-grey">
          <div className="max-width">
            <SubTitle paddingTop={120} data-aos="zoom-in">
              Team
            </SubTitle>
            <Description data-aos="zoom-in-up">
              {t(
                "BTX consists of people with expertise in legacy finance and decentralization alike; with a combined backgrounds in security, finances and technologies.  We pride ourselves on being free thinkers, and bringing innovations and financial freedom to our users through the combined knowledge of our team."
              )}
            </Description>
          </div>
        </Section>
        <TeamPictures>
          <Teams />
        </TeamPictures>
        <SlantBottom />
        <Section id="careers">
          <StyledTriangle3 className="animate__animated animate__shakeY animate__infinite animate__slower" />
          <StyledTriangle4 className="animate__animated animate__shakeY animate__infinite animate__slowest" />
          <div className="max-width">
            <SubTitle
              paddingTop={100}
              data-aos="fade-down"
              data-aos-anchor-placement="center-bottom"
            >
              Careers
            </SubTitle>
            <Description data-aos="fade-right">
              {t(
                "Our mission is to stand on the forefront of development of financial technologies, and provide accessibility to said technologies to our users in an easy-to-use format. If you share our vision for a future where blockchain is widely available to all, please contact us through the form below."
              )}
            </Description>
            <TeamBtx data-aos="fade-down-right">
              {t("Exchange the World With Us!")}
            </TeamBtx>
            <Cv
              id="contact"
              href="https://btxpro.notion.site/04c3525f4fda4403b488ef4dee042b12"
              data-aos="flip-down"
              target="_blank"
            >
              {t("View Openings")}
            </Cv>
          </div>
        </Section>
        <Section id="press">
          <div className="max-width">
            <SubTitle
              paddingTop={120}
              data-aos="fade-down"
              data-aos-anchor-placement="center-bottom"
            >
              Press
            </SubTitle>
            <Description data-aos="fade-left">
              {t(
                "Where you can listen to BTX's latest updates. Get access to our official announcements, news updates and everything else. Stay updated to the latest crypto news and trends."
              )}
            </Description>
            <PressList data-aos="fade-right" className="press-wrapper">
              <div>
                {articles.slice(0, numberOfArticlesToShow).map((article) => (
                  <PressItem
                    key={article.title.ko}
                    article={article as Article}
                  >
                    {article}
                  </PressItem>
                ))}
                {articles.length > numberOfArticlesToShow && !moreArticle && (
                  <button
                    className="more-article-btn"
                    type="button"
                    onClick={() => setMoreArticle(true)}
                  >
                    {t("more")}
                  </button>
                )}
              </div>
              {articles.length > numberOfArticlesToShow && moreArticle && (
                <>
                  <div data-aos="fade-right">
                    {articles.slice(4).map((article) => (
                      <PressItem
                        key={article.title.ko}
                        article={article as Article}
                      >
                        {article}
                      </PressItem>
                    ))}
                  </div>
                  {articles.length > numberOfArticlesToShow && moreArticle && (
                    <button
                      className="close-article-btn"
                      type="button"
                      onClick={() => setMoreArticle(false)}
                    >
                      {t("close")}
                    </button>
                  )}
                </>
              )}
            </PressList>
          </div>
        </Section>
      </StyledPage>
      <Footer lang={i18n.language}>
        <div className="max-width">
          <div className="wrapper">
            <ul>
              <li>
                <Link to="products" offset={-110} smooth={true} spy={true}>
                  PRODUCTS
                </Link>
              </li>
              <li>
                <Link to="team" offset={-110} smooth={true} spy={true}>
                  TEAM
                </Link>
              </li>
              <li>
                <Link to="careers" offset={-115} smooth={true} spy={true}>
                  CAREERS
                </Link>
              </li>
              <li>
                <Link to="press" offset={-110} smooth={true} spy={true}>
                  PRESS
                </Link>
              </li>
              <li>
                <a href="mailto:cs@childly.com">CONTACT</a>
              </li>
              <li>
                <a className="privacy" href="https://btxpro.co.kr/ko/legal/privacy-policy">{t("privacy")}</a>
              </li>
            </ul>
            {/* <aside>
              <a></a>
              <a></a>
              <a></a>
            </aside> */}
          </div>
          <address>
            {t(
              "Business Entity: Childly Corporation | CEO: Eun-tai Kim | Business Registration ID: 729-86-01268 | Address: Seoul, Seongdong-gu, Yeonmujang-5-gil, #507 & #605 | Email : cs@childly.com"
            )}
            <small>&copy; Since 2013 Childly Corporation.</small>
            <WaterMark
              src={Logo}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            />
          </address>
        </div>
      </Footer>
    </Layout>
  );
}

export default Home;
