import styled from '@emotion/styled/macro';
import { HTMLAttributes, ReactNode } from 'react';
import SubtractImg from '@/assets/images/subtract.svg';
import SubtractHoverImg from '@/assets/images/subtract_hover.svg';

const StyledHistoryItem = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 267px;
  height: 284px;
  padding: 30px 40px 38px 40px;
  box-sizing: border-box;
  background-color: transparent;
  background: url(${SubtractImg}) 0 0 no-repeat;
  background-size: cover;

  h3,
  p {
    font-family: GoodTimesHeavy;
    color: ${(p) => p.theme.colors.white};
    z-index: 1;
  }
  h3 {
    font-size: 20px;
    line-height: 1.5;
    transform: translateX(-60px);
  }
  p {
    margin-top: 26px;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 2;
    overflow: hidden;
  }

  &:hover {
    background-image: url(${SubtractHoverImg});
  }
`;

export interface HistoryItemProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  children: ReactNode;
}

function HistoryItem({ title, children, ...rest }: HistoryItemProps) {
  return (
    <StyledHistoryItem {...rest}>
      <h3>{title}</h3>
      <p>{children}</p>
    </StyledHistoryItem>
  );
}

export default HistoryItem;
