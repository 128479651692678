import Logo from "@/assets/images/childly_logo_white.png";
import styled from "@emotion/styled/macro";
import MenuIcon from "../atoms/MenuIcon";
import i18n from "@/utils/i18n";

const WaterMark = styled.img`
  display: inline-block;
  transform: translateX(24px);
  width: 116px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledMobileHeader = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  align-items: end;
  padding: 70px 40px 30px 40px;
  background: #2d2d2f;
  overflow: auto;
  z-index: 999;

  nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    overflow: auto;
  }

  li {
    float: left;
    font-size: 16px;
    font-weight: 400;
  }

  li + li {
    margin-left: 20px;
  }

  a {
    display: inline-block;
  }

  aside {
    a {
      padding-bottom: 0;
      border-width: 1px;
    }
  }

  a {
    opacity: 0.2;
    padding-bottom: 33px;
    border-bottom: 2px solid transparent;

    &.active,
    &:hover {
      opacity: 1;
      border-color: #fff;
    }
  }
`;

interface MobileHeaderProps {
  onToggle: () => void;
}

function MobileHeader({ onToggle }: MobileHeaderProps) {
  const changeLanguage = (lng: "ko" | "en") => {
    i18n.changeLanguage(lng);
  };

  return (
    <StyledMobileHeader>
      <nav className="max-width">
        <MenuIcon onClick={onToggle} />
        <WaterMark
          src={Logo}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
        <aside>
          <dl>
            <li>
              <a
                onClick={() => changeLanguage("ko")}
                className={i18n.language.includes("ko") ? "active" : ""}
              >
                KR
              </a>
            </li>
            <li>
              <a
                onClick={() => changeLanguage("en")}
                className={i18n.language.includes("en") ? "active" : ""}
              >
                EN
              </a>
            </li>
          </dl>
        </aside>
      </nav>
    </StyledMobileHeader>
  );
}

export default MobileHeader;
