import styled from '@emotion/styled/macro';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';

const Wrapper = styled.div`
  position: absolute;
  bottom: -38px;
  right: -38px;
  max-width: 532px;
  width: 100%;
  height: 480px;
  overflow-y: hidden;
`;

const Bubble = styled.span<{ size?: number; left?: number; speed?: number }>`
  display: block;
  position: absolute;
  left: ${(p) => p.left}px;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  bottom: -50px;
  border-radius: 50%;
  border: 1px solid $border;
  background: rgb(16, 77, 74);
  animation: rise ${(p) => p.speed}s ease-in infinite;

  @keyframes moveclouds {
    0% {
      top: 500px;
      opacity: 1;
    }
    100% {
      top: -100px;
      opacity: 0;
    }
  }

  @keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 50px;
    }
  }
`;

function Bubbles({ ...rest }) {
  const unit = useRef(60);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [bubbles, setBubbles] = useState<ReactNode[]>([]);

  useEffect(() => {
    function getBubbleSize() {
      return Math.floor(Math.random() * 15) + 7;
    }

    function getBubbleLocation() {
      return Math.floor(Math.random() * 450) + 30;
    }

    function createBubble() {
      const width = getBubbleSize();

      const style = {
        width,
        height: width,
        animation: `moveclouds ${Math.floor(Math.random() * 12) + 4}s linear infinite, sideWays ${
          Math.floor(Math.random() * 5) + 1
        }s ease-in-out infinite alternate`,
        left: getBubbleLocation(),
      };

      return <Bubble style={style} />;
    }

    const bubbles = Array(unit.current)
      .fill(0)
      .reduce((acc, v) => {
        acc.push(createBubble());
        return acc;
      }, []);

    setBubbles(bubbles);
  }, []);

  return (
    <Wrapper ref={wrapperRef} {...rest}>
      {bubbles.map((v, i) => (
        <Fragment key={i}>{v}</Fragment>
      ))}
    </Wrapper>
  );
}

export default Bubbles;
