import Logo from "@/assets/images/childly_logo_white.png";
import styled from "@emotion/styled/macro";
import { Link } from "react-scroll";

const WaterMark = styled.img`
  width: 116px;
`;

const StyledSideBar = styled.div<{ isShow: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  background-color: rgb(38, 38, 39);
  z-index: 9999999;

  ul {
    margin-top: 50px;

    li + li {
      margin-top: 24px;
    }
  }

  a {
    font-size: 32px;
    opacity: 0.2;

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  img {
    margin-top: auto;
  }

  ${(p) =>
    !p.isShow &&
    `
    display:none

  `}
`;

const CloseIcon = styled.div`
  position: relative;
  margin-left: auto;
  width: 50px;
  height: 50px;
  opacity: 0.8;

  &:before,
  &:after {
    position: absolute;
    left: 24px;
    content: " ";
    height: 50px;
    width: 2px;
    background-color: #fff;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

interface SideBarProps {
  onClose: () => void;
  isShow: boolean;
}

function SideBar({ isShow, onClose }: SideBarProps) {
  const handleLogoClick = () => {
    onClose();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <StyledSideBar isShow={isShow}>
      <CloseIcon onClick={onClose} />
      <ul>
        <li>
          <Link to="products" smooth={true} spy={true} onClick={onClose}>
            PRODUCTS
          </Link>
        </li>
        <li>
          <Link to="team" smooth={true} spy={true} onClick={onClose}>
            TEAM
          </Link>
        </li>
        <li>
          <Link to="careers" smooth={true} spy={true} onClick={onClose}>
            CAREERS
          </Link>
        </li>
        <li>
          <Link to="press" smooth={true} spy={true}>
            PRESS
          </Link>
        </li>
        <li>
          <a href="mailto:cs@childly.com" onClick={onClose}>
            CONTACT
          </a>
        </li>
      </ul>
      <WaterMark src={Logo} onClick={handleLogoClick} />
    </StyledSideBar>
  );
}

export default SideBar;
