import { ReactNode } from 'react';
import styled from '@emotion/styled/macro';

interface PageProps {
  className?: string;
  children: ReactNode;
}

const StyledMain = styled.main`
  box-sizing: content-box;

  * {
    color: ${(p) => p.theme.colors.white};
  }
`;

function Page({ className, children }: PageProps) {
  return <StyledMain className={className}>{children}</StyledMain>;
}

export default Page;
