import styled from '@emotion/styled/macro';

export const StyledSlantBottomWrapper = styled.div`
  position: relative;
  top: auto;
  right: 0px;
  bottom: auto;
  overflow: hidden;
  width: 100%;
  height: 14vh;
  max-height: 180px;
  background-color: transparent;
  transform: skew(0deg, 0deg) translate3d(0px, 0px, 24px);
  color: transparent;
  transform-style: preserve-3d;
`;

const StyledSlantBottom = styled.div`
  position: absolute;
  right: 0px;
  z-index: 0;
  width: 45%;
  height: 15vh;
  max-height: 180px;
  float: left;
  background-color: rgb(38, 38, 39);
  transform: skew(30deg, 0deg);
`;

const StyledSlantBottomInSpace = styled.div`
  position: absolute;
  right: 0px;
  width: 50px;
  height: 14vh;
  max-height: 180px;
  background-color: rgb(38, 38, 39);
`;

function SlantBottom() {
  return (
    <StyledSlantBottomWrapper>
      <StyledSlantBottom />
      <StyledSlantBottomInSpace />
    </StyledSlantBottomWrapper>
  );
}

export default SlantBottom;
